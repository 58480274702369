import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import MenuItems from "./MenuItems";
import OffCanvasMenu from "./OffCanvasMenu";
import logo from "../../assets/images/logoRTLx.png";
import logoDark from "../../assets/images/logo-dark.png";

const Header = (props) => {
  const {
    parentMenu,
    headerClass,
    headerNormalLogo,
    headerStickyLogo,
    signUpBtn,
  } = props;

  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1024);
  const [stickyHeader, setStickyHeader] = useState(false);

  // Handle screen size detection
  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle sticky header
  useEffect(() => {
    const handleScroll = () => {
      setStickyHeader(window.scrollY > 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        className={
          stickyHeader ? headerClass + " sc-header-sticky" : headerClass
        }
        id="sc-header-sticky"
      >
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            {/* OffCanvas Area */}
            <div className="col-lg-4 col-md-8 col-sm-9">
              <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-start">
                <div className="header-list">
                  <ul className="d-flex">
                    <li className="canvas sc-pr-20 sc-sm-pr-10 text-white d-lg-none">
                      <OffCanvasMenu />
                    </li>
                  </ul>
                </div>
                {isLaptop && (
                  <div className="header-btn2 sc-pl-30 sc-sm-pl-20 d-flex align-items-center">
                    <a
                      href="https://admin.beautyplus.cloud/login"
                      className={signUpBtn ? signUpBtn : "sign-in-btn"}
                    >
                      <span>
                        <i className="ri-lock-line"> </i>התחברות
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8 col-md-4 col-sm-3">
              <div className="sc-header-left-wraper d-flex align-items-center justify-content-sm-end">
                <div className="sc-main-menu d-lg-block d-none">
                  <ul className="main-menu">
                    <MenuItems parentMenu={parentMenu} />
                  </ul>
                </div>
                <div className="sc-main-logo sc-mr-60 sc-md-mr-30 text-center text-sm-start">
                  <div className="default-logo logo-area">
                    <Link to="/#">
                      <img
                        src={headerNormalLogo ? headerNormalLogo : logo}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="sticky-logo logo-area">
                    <Link to="/#">
                      <img
                        src={headerStickyLogo ? headerStickyLogo : logoDark}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
