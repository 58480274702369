import React, { useState } from "react";
import { Fade } from "react-reveal";
import { FaWhatsapp } from "react-icons/fa";

const PricePlan = () => {
  const [priceToggle, setPriceToggle] = useState(true);

  const handleWhatsAppClick = () => {
    const phonenumber = "0544311942";
    const whatsappUrl = `https://web.whatsapp.com/send?phone=+972${phonenumber}&text=${encodeURIComponent(
      "היי,אשמח לשמוע פרטים על האפליקציה לעסק"
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  // Common style for UL lists in all cards
  const ulStyle = {
    textAlign: "right",
    padding: 0,
    listStyle: "none",
    marginBottom: "20px",
    lineHeight: "1.6",
  };

  // Common style for LI items to create spacing
  const liStyle = {
    marginBottom: "10px", // Extra spacing between items
  };

  return (
    <section
      className="sc-price-area"
      style={{
        paddingTop: "100px",
        paddingBottom: "100px",
        background: "#f5f7fa",
      }}
    >
      <div className="container">
        <div
          style={{
            marginBottom: "40px",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "2.5rem",
              fontWeight: "700",
              color: "#333",
              marginBottom: "10px",
              textShadow: "0 0 5px rgba(255,255,255,0.6)", // Light text glow
            }}
          >
            התוכניות שלנו
          </h2>
          <p style={{ color: "#666", fontSize: "1.2rem" }}>
            בחרו את החבילה המתאימה ביותר לעסק שלכם
          </p>
        </div>

        {/* 
        // If you have a toggle for monthly/yearly or other logic:
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <button onClick={() => setPriceToggle(true)}>Monthly</button>
          <button onClick={() => setPriceToggle(false)}>Yearly</button>
        </div>
        */}

        {/* Monthly Pricing */}
        <div className={priceToggle ? "pricing-monthly" : "d-none"}>
          <div className="row">
            {/* MAXIMUM CARD */}
            <Fade bottom delay={100}>
              <div
                className="col-lg-3 col-md-6"
                style={{
                  marginBottom: "30px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(135deg, #ff9966 0%, #ff5e62 100%)",
                    color: "#fff",
                    borderRadius: "30px",
                    padding: "30px 20px",
                    // Neon-like glow box shadow
                    boxShadow: "0 0 20px 3px rgba(255, 94, 98, 0.5)",
                    transition: "transform 0.3s ease",
                  }}
                  className="price-card"
                >
                  <h4
                    style={{
                      fontSize: "1.8rem",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      textShadow: "0 0 5px rgba(255,255,255,0.8)", // text glow
                    }}
                  >
                    Maximum
                  </h4>
                  <div>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "2.0rem",
                        fontWeight: "bold",
                      }}
                    >
                      תלוי בעסק
                    </span>
                  </div>
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "#f1f1f1",
                    }}
                  >
                    חבילה מיוחדת לעסקים גדולים
                  </p>
                  <ul style={ulStyle}>
                    <li style={liStyle}>כמות עובדים גדולה יותר</li>
                    <li style={liStyle}>כמות משתמשים גדולה יותר</li>
                    <li style={liStyle}>כמות סמסים גדולה יותר</li>
                  </ul>
                  <button
                    style={{
                      backgroundColor: "#fff",
                      color: "#ff5e62",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "12px 0",
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    <FaWhatsapp style={{ marginRight: "8px" }} />
                    צרו קשר
                  </button>
                </div>
              </div>
            </Fade>

            {/* ULTIMATE CARD */}
            <Fade bottom delay={200}>
              <div
                className="col-lg-3 col-md-6"
                style={{
                  marginBottom: "30px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(135deg, #06beb6 0%, #48b1bf 100%)",
                    color: "#fff",
                    borderRadius: "30px",
                    padding: "30px 20px",
                    boxShadow: "0 0 20px 3px rgba(72, 177, 191, 0.5)",
                    transition: "transform 0.3s ease",
                  }}
                  className="price-card"
                >
                  <h4
                    style={{
                      fontSize: "1.8rem",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      textShadow: "0 0 5px rgba(255,255,255,0.8)",
                    }}
                  >
                    Ultimate
                  </h4>
                  <div>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "2.0rem",
                        fontWeight: "bold",
                      }}
                    >
                      ₪379
                    </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: "1rem",
                        marginTop: "-15px",
                      }}
                    >
                      לחודש
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      חבילה מורחבת
                    </p>
                    <p
                      style={{
                        direction: "rtl",
                        overflow: "hidden",
                        color: "white",
                      }}
                    >
                      <span>7000 </span>
                      <span>סמסים מתנה בהצטרפות</span>
                    </p>
                  </div>
                  <ul style={ulStyle}>
                    <li style={liStyle}>יומן דיגיטלי</li>
                    <li style={liStyle}>קופה מלאה כולל סליקה</li>
                    <li style={liStyle}>ניהול רכש ומלאי</li>
                    <li style={liStyle}>שימוש במערכת גם מהנייד</li>
                    <li style={liStyle}>עד 5 מטפלים</li>
                    <li style={liStyle}>עד 5 עובדים</li>
                    <li style={liStyle}>עד 2 משתמשים</li>
                    <li style={liStyle}>תזכורות סמס</li>
                    <li style={liStyle}>הצעות מחיר</li>
                    <li style={liStyle}>גלריית תמונות</li>
                    <li style={liStyle}>שעון נוכחות</li>
                    <li style={liStyle}>סדרות מנויים</li>
                  </ul>
                  <p style={{ fontSize: "0.85rem", lineHeight: "1.4" }}>
                    זימון תורים ללקוח כלול (ללא הגבלה)
                    <br />
                    כל מטפל או עובד נוסף 19.90
                    <br />
                    כלול WhatsApp אוטומציות
                    <FaWhatsapp
                      size={15}
                      color="green"
                      style={{ marginLeft: "5px" }}
                    />
                    <br />
                    טפסים דיגטליים 59.90(ללא הגבלה)
                    <br />
                    לינק לתשלום 29.90(ללא הגבלה)
                    <br />
                    חיבור לידים חיצוני 19.90
                    <br />
                    (כלול בחבילה) שוברי גיפט-קארד דיגיטלי
                  </p>
                  <button
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#fff",
                      color: "#48b1bf",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "12px 0",
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    <FaWhatsapp style={{ marginRight: "8px" }} />
                    צרו קשר
                  </button>
                  המחירים אינם כוללים מע״מ
                </div>
              </div>
            </Fade>

            {/* PRO CARD (with ribbon) */}
            <Fade bottom delay={300}>
              <div
                className="col-lg-3 col-md-6"
                style={{
                  marginBottom: "30px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {/* Ribbon */}
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "-10px",
                    backgroundColor: "#f093fb",
                    color: "#fff",
                    padding: "5px 30px",
                    transform: "rotate(-45deg)",
                    fontWeight: "bold",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                  }}
                >
                  פופולרי
                </div>
                <div
                  style={{
                    background:
                      "linear-gradient(135deg, #f093fb 0%, #f5576c 100%)",
                    color: "#fff",
                    borderRadius: "30px",
                    padding: "30px 20px",
                    boxShadow: "0 0 20px 3px rgba(245, 87, 108, 0.5)",
                    transition: "transform 0.3s ease",
                  }}
                  className="price-card"
                >
                  <h4
                    style={{
                      fontSize: "1.8rem",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      textShadow: "0 0 5px rgba(255,255,255,0.8)",
                    }}
                  >
                    Pro
                  </h4>
                  <div>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "2.0rem",
                        fontWeight: "bold",
                      }}
                    >
                      ₪269
                    </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: "1rem",
                        marginTop: "-15px",
                      }}
                    >
                      לחודש
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        color: "#f1f1f1",
                      }}
                    >
                      חבילה מקצועית
                    </p>
                    <p style={{ direction: "rtl", overflow: "hidden" }}>
                      <span>3000 </span>
                      <span>סמסים מתנה בהצטרפות</span>
                    </p>
                  </div>
                  <ul style={ulStyle}>
                    <li style={liStyle}>יומן דיגיטלי</li>
                    <li style={liStyle}>קופה מלאה כולל סליקה</li>
                    <li style={liStyle}>ניהול רכש ומלאי</li>
                    <li style={liStyle}>שימוש במערכת גם מהנייד</li>
                    <li style={liStyle}>עד 3 מטפלים</li>
                    <li style={liStyle}>עד 3 עובדים</li>
                    <li style={liStyle}>עד 2 משתמשים</li>
                    <li style={liStyle}>תזכורות סמס</li>
                    <li style={liStyle}>הצעות מחיר</li>
                    <li style={liStyle}>גלריית תמונות</li>
                    <li style={liStyle}>שעון נוכחות</li>
                    <li style={liStyle}>סדרות מנויים</li>
                    <li style={{ fontSize: "0.85rem", lineHeight: "1.4" }}>
                      זימון תורים ללקוח 39.90(ללא הגבלה)
                      <br />
                      כל מטפל או עובד נוסף 19.90
                      <br />
                      39.90 WhatsApp אוטומציות
                      <FaWhatsapp
                        size={15}
                        color="green"
                        style={{ marginLeft: "5px" }}
                      />
                      <br />
                      טפסים דיגטליים 59.90(ללא הגבלה)
                      <br />
                      לינק לתשלום 29.90(ללא הגבלה)
                      <br />
                      חיבור לידים חיצוני 19.90
                      <br />
                      19.90 שוברי גיפט-קארד דיגיטלי
                    </li>
                  </ul>
                  <button
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#fff",
                      color: "#f5576c",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "12px 0",
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    <FaWhatsapp style={{ marginRight: "8px" }} />
                    צרו קשר
                  </button>
                  המחירים אינם כוללים מע״מ
                </div>
              </div>
            </Fade>

            {/* BASIC CARD */}
            <Fade bottom delay={400}>
              <div
                className="col-lg-3 col-md-6"
                style={{
                  marginBottom: "30px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)",
                    color: "#333",
                    borderRadius: "30px",
                    padding: "30px 20px",
                    boxShadow: "0 0 20px 3px rgba(161, 196, 253, 0.5)",
                    transition: "transform 0.3s ease",
                  }}
                  className="price-card"
                >
                  <h4
                    style={{
                      fontSize: "1.8rem",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      color: "#333",
                      textShadow: "0 0 3px rgba(255,255,255,0.6)",
                    }}
                  >
                    Basic
                  </h4>
                  <div>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "2.0rem",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      ₪179
                    </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: "1rem",
                        marginTop: "-15px",
                        color: "white",
                      }}
                    >
                      לחודש
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      חבילה בסיסית
                    </p>
                    <p
                      style={{
                        direction: "rtl",
                        overflow: "hidden",
                        color: "white",
                      }}
                    >
                      <span>1500 </span>
                      <span>סמסים מתנה בהצטרפות</span>
                    </p>
                  </div>
                  <ul style={ulStyle}>
                    <li style={{ ...liStyle, color: "white" }}>יומן דיגיטלי</li>
                    <li style={{ ...liStyle, color: "white" }}>
                      שימוש במערכת גם מהנייד
                    </li>
                    <li style={{ ...liStyle, color: "white" }}>עובד 1</li>
                    <li style={{ ...liStyle, color: "white" }}>עד מטפל 1</li>
                    <li style={{ ...liStyle, color: "white" }}>עד 2 משתמשים</li>
                    <li style={{ ...liStyle, color: "white" }}>תזכורות סמס</li>
                    <li style={{ ...liStyle, color: "white" }}>
                      גלריית תמונות
                    </li>
                    <li style={{ ...liStyle, color: "white" }}>שעון נוכחות</li>
                    <li style={{ ...liStyle, color: "white" }}>סדרות מנויים</li>
                  </ul>
                  <p
                    style={{
                      fontSize: "0.85rem",
                      lineHeight: "1.4",
                      color: "white",
                    }}
                  >
                    זימון תורים ללקוח 39.90(ללא הגבלה)
                    <br />
                    כל מטפל או עובד נוסף 19.90
                    <br />
                    39.90 WhatsApp אוטומציות
                    <FaWhatsapp
                      size={15}
                      color="green"
                      style={{ marginLeft: "5px" }}
                    />
                    <br />
                    טפסים דיגטליים 59.90(ללא הגבלה)
                    <br />
                    חיבור לידים חיצוני 19.90
                  </p>
                  <button
                    style={{
                      marginTop: "20px",
                      backgroundColor: "white",
                      color: "skyblue",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "12px 0",
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    <FaWhatsapp style={{ marginRight: "8px" }} />
                    צרו קשר
                  </button>
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    {" "}
                    המחירים אינם כוללים מע״מ
                  </p>
                </div>
              </div>
            </Fade>

            {/* FOOTER NOTE */}
            <div
              style={{
                backgroundColor: "#fff",
                color: "#333",
                padding: "15px",
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "40px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                borderRadius: "8px",
              }}
            >
              תמיכה כלולה בכל החבילות ללא עלות נוספת ✔️ ללא הגבלה ללא חוזים, בטל
              בכל זמן ✔️
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricePlan;
